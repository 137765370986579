import "./style.scss";

import { graphql } from "gatsby";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import {
  contactUs,
  faqDetail,
  faqHeading,
  insideLink,
  renderCom,
} from "../../components/ForMarkDownComponents";
import { createClassName, setLocaleContent } from "../../helpers";
import { imageManager, noBase64FluidImage, useImageRendering } from "../../components/Image";

import Benefits from "../../components/Benefits";
import Container from "../../components/Container";
import FreeTrialForm from "../../components/Forms/_freeTrial";
import Heading from "../../components/Heading";
import Layout from "../../layouts";
import SystemRequirements from "../../components/SystemRequirement";
import Testimonial from "../../components/Testimonial";

const cn = createClassName("download");

const renderComToolkit = {
  h2: faqHeading,
  detail: faqDetail,
  "inside-link": insideLink,
  "contact-us": contactUs,
};

const downloadRenderCom = renderCom(renderComToolkit);

const bannerImage = (props) => {
  const { alt, testimonial } = props;
  const Image = useImageRendering(props);
  return (
    <>
      <div className={cn("banner", { wrap: true })}>
        <Image
          src={`start-free-trial-xltools-add-in-for-excel-banner${setLocaleContent(
            "",
            "-",
          )}.png`}
          className={cn("banner", { img: true })}
          alt={alt}
          eager
        />
      </div>
      <div className={cn("banner", { testimonial: true })}>
        <Testimonial {...testimonial} position="row" isItalicStyle />
      </div>
    </>
  );
};

const Download = ({ data }) => {
  const { htmlAst, frontmatter } = data.markdownRemark;
  const { t } = useTranslation("strings");
  const imageRenderer = imageManager(data.bannerImages);

  const formTitle = frontmatter.formTitle.map((text) => (
    <span key={text}>
      {text}
    </span>
  ));

  return (
    <Layout
      // headerMenu={false}
      footer={false}
      isFreeTrial={false}
      {...frontmatter.seo[0]}
    >
      <section aria-label="heading" className={cn("heading", { wrap: true })}>
        <Container>
          <div className={cn({ wrap: true })}>
            <div className={cn({ block: true })}>
              <div>
                <Heading level={4} weight="light">
                  {frontmatter.bannerSubTitle}
                </Heading>
                <Heading
                  level={1}
                  weight="bold"
                  className={cn("heading", { title: true })}
                >
                  {frontmatter.bannerTitle}
                </Heading>
              </div>
              <div className={cn({ block: "img", adaptive: true })}>
                {bannerImage({
                  alt: frontmatter.alt,
                  testimonial: frontmatter.testimonial,
                  imageRenderer,
                })}
              </div>
              <div className={cn("form", { wrap: true })}>
                <Heading level={2} weight="light" fontLevel={4} className={cn("form", { heading: true })}>
                  {formTitle}
                </Heading>
                <FreeTrialForm
                  messageClassName={cn("form", { message: "error" })}
                  // emailClassName={cn("form", { email: true })}
                  buttonClassName={cn("form", { button: true })}
                  spinerWrapClassName={cn("form", { button: "spiner" })}
                />
              </div>
            </div>
            <div className={cn({ block: "img" })}>
              {bannerImage({
                alt: frontmatter.alt,
                testimonial: frontmatter.testimonial,
                imageRenderer,
              })}
            </div>
          </div>
        </Container>
      </section>
      <section aria-label="benefits">
        <Benefits list={frontmatter.benefits} label="benefits download page" />
      </section>
      <section aria-label="System Requirements">
        <Container className={cn("requirements", { wrap: true })}>
          <Heading
            level={2}
            weight="light"
            className={cn(cn("requirements", { heading: true }))}
          >
            {t("requirements")}
          </Heading>
          <SystemRequirements
            listClassName={cn("requirements", { list: true })}
            itemClassName={cn("requirements", { configuration: true })}
          />
        </Container>
        <div className={cn("line")} />
      </section>
      <section aria-label="faq">
        <Container className={cn("faq", { wrap: true })}>
          {downloadRenderCom(htmlAst)}
        </Container>
      </section>
    </Layout>
  );
};

export default Download;

export const query = graphql`
  query getDownload($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        seo {
          title
          description
          link
          image
        }
        bannerTitle
        bannerSubTitle
        alt
        formTitle
        benefits {
          title
          description
        }
        testimonial {
          item
          author
          skill
        }
      }
    }
    bannerImages:allFile(
      filter: {
        relativePath: {
          regex: "/^images/start-free-trial-xltools-add-in-for-excel-banner/"
        }
      }
    )
    {
      nodes {
        ...noBase64FluidImage
      }
    }
  }
`;
